import React, { useEffect,useContext, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import "./OrderListing.scss";
import filterIcon from "assets/images/Icon_Filter_ckc.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import Paginator from "shared/paginator/Paginator";
import ListingRow from "./ListingRow";
import { getOrders, getOrder} from "services/api/order";
import LoaderBlock from "shared/loader/LoaderBlock";
import ExportCsv from "./ExportCsv";
import backArrowIcon from "assets/images/back-arrow.svg";
import refreshIcon from "assets/images/icon-refresh.svg";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import UserContext from "store/user-context";
import { toast, ToastContainer } from "react-toastify";
import brownBellIcon from "assets/images/order-bell-brownIcon.svg";
import closeIcon from "assets/images/toast-close-icon.svg";
import OrdersNotificationContext from "store/orders-notification-context";
import newOrderSound from "assets/sounds/bell.mp3";
import OrderFilterModal from "./OrderFilterModal";
import { getActiveKitchens } from "services/api/kitchen";
import { RoleType } from "constants/roleType";
import fullScreenIcon from "assets/images/icon-fullscreen.svg";
import { useMediaQuery } from "react-responsive";
import { DriverStatus } from "constants/orderStatus";
import { ServiceId } from "constants/serviceType";

const orderParams = new URLSearchParams();

const OrderListing = () => {
  const hubUrl = process.env.REACT_APP_ORDER_HUB_URL;
  const orderHubListener = process.env.REACT_APP_ORDER_HUB_LISTENER;
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const OrdersNotificationCtx = useContext(OrdersNotificationContext);
  const useFilters = OrdersNotificationCtx?.filtersData;

  const history = useHistory();
  const { search } = useLocation();

  const pageSize = 30;
  const exportsPageSize = 250;
  const [pageNum, setpageNum] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const orderListingRef = useRef(allOrders);
  const [totalRecords, settotalRecords] = useState(0);
  const [connection, setConnection] = useState(null);
  const [latestOrderStatus, setLatestOrderStatus] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const max_toast = 3;
  const [isOpen, setIsOpen] = useState(false);
  const [kitchenTitle, setKitchenTitle] = useState(null);
  const [kitchenId, setKitchenId] = useState(null);
  const isFullScreen = OrdersNotificationCtx.isFullScreen;
  const searchTerm = useFilters?.searchTerm;
  const orderStatus = useFilters?.orderStatus;
  const marketPlaceStatus = useFilters?.marketPlaceStatus;
  const dateTo = useFilters?.dateTo;
  const dateFrom = useFilters?.dateFrom;
  const selectedBrandIds = useFilters?.selectedBrandIds;
  const selectedKitchenId = useFilters?.selectedKitchenId || kitchenId;
  const kitchenIdRef = useRef(kitchenId);
  const KitchenRoles = [RoleType.KITCHEN_OWNER, RoleType.KITCHEN_STAFF];

   const isMobile = useMediaQuery({
     query: "(max-width: 767px)",
   });

  useEffect(() => {
    if (KitchenRoles.includes(userRole)) {
      getKitchensForDD();
    }
    return () => {
      OrdersNotificationCtx.saveFullScreenState(false);
      OrdersNotificationCtx.saveOrderNotifications([]);
      const msInADay = 86400000;
      const defaultMarketPlaceIds = [
        ServiceId.DOORDASH,
        ServiceId.GRUBHUB,
        ServiceId.UBEREATS,
        ServiceId.POSTMATES,
        ServiceId.FOODDUDES,
        ServiceId.MENUFY,
      ];
      OrdersNotificationCtx.saveFiltersData({
        dateFrom: new Date(Date.now() - msInADay * 7),
        dateTo: new Date(),
        selectedTimeRange: 3,
        marketPlaceStatus: defaultMarketPlaceIds,
      });
    };
  }, []);

  useEffect(() => {
        if (search) {
      let page = new URLSearchParams(search).get("page");
      if (page) {
        setpageNum(+page);
        orderParams.set("page", page);
      } else {
        setpageNum(1);
        orderParams.delete("page");
      }
    } else {
      setpageNum(1);
      orderParams.delete("page");
      orderParams.delete("search");
    }
  }, [search]);

  useEffect(() => {
    // pageNum !== 1 ? onPageChange(1) : 
    getListing();
  }, [useFilters, pageNum, kitchenId]);

useEffect(() => {
  // initiate the signalR connection on page load
  initialiseRtConnection();
  return () => {
    if (connection) {
      connection.off(orderHubListener, handleRtData);
      connection.stop().catch((error) => {
        console.error("Error while disconnecting::", error);
      });
    }
  };
}, []);

  useEffect(() => {
    // if it is connected then start listening to the server;
    if (connection?.state === HubConnectionState.Disconnected) {
      startConnection();
    }
  }, [connection]);

  const getListing = async () => {
    setIsRefresh(false);
    let masks = {
      pageNum: pageNum,
      pageSize: pageSize,
      name: searchTerm,
      kitchenId: selectedKitchenId,
      orderStatus: orderStatus,
      marketPlaceStatus: marketPlaceStatus,
      dateTo: dateTo,
      dateFrom: dateFrom,
      brandIds: selectedBrandIds,
    };
    setisLoading(true);
    await getOrders(masks)
      .then((res) => {
        let data = res.data;
        setAllOrders(data.order);
        orderListingRef.current = data.order; 
        settotalRecords(data.pagingParams.totalRecords);
        setisLoading(false);
      })
      .catch(() => {
        setAllOrders([]);
        orderListingRef.current = []; 
        settotalRecords(0);
        setisLoading(false);
      });
  };

  const initialiseRtConnection = () => {
    // start and connect with server hub
    const newConnection = new HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect([0, 3000])
      .build();
    setConnection(newConnection);
  };

  const startConnection =  () => {
       connection 
        .start()
        .then(() => {
          registerHubEvents();
          listeningRtData();
          console.log("connection listeningRtData")
        })
        .catch((err) => { 
          console.log("connection error", err);
        });
  };

  const listeningRtData = () => {
    connection.on(orderHubListener, handleRtData);
  };

  const registerHubEvents = () => {
    connection.onclose(() => {
      if (connection?.state === HubConnectionState.Disconnected) {
        startConnection();
      }
    });
  };

  const handleRtData = (message) => {
    const orderExists = orderListingRef.current.some(
      (item) => item.id === message.orderId
    );
    if (
      message.orderId > 0 &&
      !orderExists &&
      message.deliveryStatus !== DriverStatus.COURIERLOCATIONUPDATED
    ) {
      getOrderDetails(message.orderId, message.statusId);
    }
    if (message.deliveryStatus !== DriverStatus.COURIERLOCATIONUPDATED) {
      setLatestOrderStatus(message);
      OrdersNotificationCtx.saveUpdatedOrderDetails(message);
    }
  };

  const getOrderDetails = async (orderId, statusId) => {
    await getOrder(orderId)
      .then((res) => {
        const data = res.data;
        if (statusId === 1 || (statusId === 2 && data?.posOrderId)) {
          const content =
            statusId === 2
              ? "New Order Received from " +
                data.virtualBrand?.name +
                " - OrderId - " +
                orderId
              : "New Order Received from " +
                data.virtualBrand?.name +
                "Please Accept. - OrderId - " +
                orderId;
          if (
            (kitchenIdRef.current > 0 &&
              data.kitchen?.id === kitchenIdRef.current) ||
            !kitchenIdRef.current
          ) {
            setNotifications([
              ...notifications,
              { id: orderId, message: content, evt: statusId },
            ]);
          }
          if (data && matchesFilters(data, useFilters)) {
            let updatedOrders = [];
            setAllOrders((prevOrders) => {
              updatedOrders = [data, ...prevOrders];
              if (updatedOrders.length > 30) {
                updatedOrders.splice(-1, 1);
              }
              return updatedOrders;
            });
            orderListingRef.current = updatedOrders;
          }
        }
      })
      .catch(() => {
        setAllOrders([]);
      });
  };

  const matchesFilters = (order, filters) => {
    if (filters.marketPlaceStatus && filters.marketPlaceStatus.length > 0) {
      if (!filters.marketPlaceStatus.includes(order.deliveryService?.id)) {
        return false;
      }
    }
    if (filters.orderStatus && filters.orderStatus.length > 0) {
      if (!filters.orderStatus.includes(order.orderStatusId)) {
        return false;
      }
    }
    if (filters.dateTo && filters.dateFrom) {
      if (
        !order.orderDate >= filters.dateFrom &&
        !order.orderDate <= filters.dateTo
      ) {
        return false;
      }
    }
    if (filters.searchTerm) {
      if (!order.customer?.name === filters.searchTerm) {
        return false;
      }
    }
    if (filters.selectedBrandIds && filters.selectedBrandIds.length > 0) {
      if (!filters.selectedBrandIds.includes(order.virtualBrand?.id)) {
        return false;
      }
    }
    if (filters.selectedKitchenId > 0) {
      if (!filters.selectedKitchenId.includes(order.kitchen?.id)) {
        return false;
      }
    }
    return true;
  };

  const onPageChange = (page) => {
    if (page) {
      orderParams.set("page", page);
    } else {
      orderParams.delete("page");
    }
    history.push({ search: orderParams.toString() });
  };
  
  useEffect(() => {
    if (notifications?.length) {
      notify();
      setIsRefresh(true);
    }
  }, [notifications]);

    const getIcons = (toastId, evt) => {
      /* if (evt === OrderStatus.NEW) return brownBellIcon;
      else if (toastId === 2) return greenBellIcon;
      else return redBellIcon; */
      return brownBellIcon;
    };

    const getClassName = (toastId, evt) => {
      /* if (evt === OrderStatus.NEW) return "yellow-toast";
      else if (toastId === 2) return "green-toast";
      else */ return "yellow-toast";
    };

  const notify = () => {
    if (notifications?.length) {
      notifications?.reverse().map((ele) => {
        return toast(ele?.message, {
          containerId: "local",
          className: `notification_container toast-dimensions toast-text ${getClassName(
            ele?.id,
            ele?.evt
          )}`,
          toastId: ele?.id,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          newestOnTop: true,
          closeButton: true,
          icon: <img src={getIcons(ele?.id, ele?.evt)} alt="custom icon" />,
          onOpen: () => handleToastOpen(),
          onClick: (e) => handleToastClick(e, ele?.id),
        });
      });
    }
  };
  
  const handleToastOpen = () => {
    const audio = new Audio(newOrderSound);
    setTimeout(() => {
      audio.play().catch((err) => {
        console.error("Audio play failed:", err);
      });
    }, 100);
  };

  const handleToastClick = (e, id) => {
    const ele = document.getElementsByClassName('Toastify__toast-container');
    ele?.length && ele[0]?.classList.add('active');
    if (e?.target?.className === "toast-close-icon") {
      setNotifications(notifications?.filter((obj) => obj.id !== id));
    }
  };

  const onOrderFilterToggle = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getKitchensForDD = async () => {
    await getActiveKitchens().then((res) => {
      let data = res.data;
      if (data && data.length) {
        if (KitchenRoles.includes(userRole)) {
          setKitchenTitle(data[0]?.name);
          setKitchenId(data[0]?.id);
          kitchenIdRef.current = data[0]?.id;
        }
      }
    });
  };

  const onOrderFullScreenToggle = () => {
    OrdersNotificationCtx.saveFullScreenState(!isFullScreen);
  };

  const CustomCloseButton = ({ closeToast }) => (
      <img className="toast-close-icon" src={closeIcon} onClick={closeToast} alt=""/>
  );

  return (
    <>
      {!isFullScreen && (
        <Subheader>
          <div className="goBack mb-3">
            <Link
              to="/dashboard"
              className="back-btn-red"
              title="Go to Dashboard"
            >
              <span className="icons">
                <img src={backArrowIcon} alt="back icon" />
              </span>
              Go to Dashboard
            </Link>
          </div>
        </Subheader>
      )}
      <div className="orders__page__wrapper">
        <div className="managermodifier__wrapper basic__info__content">
          <div
            className={"container " + (isFullScreen ? "orders__container" : "")}
          >
            <div
              className={
                "managermodifier__header " +
                (isFullScreen ? "fullscreen__no__padding" : "")
              }
            >
              <div className="row justify-content-between">
                {!isFullScreen &&
                  ![RoleType.KITCHEN_STAFF].includes(userRole) && (
                    <div className="col-12 mb-3 pb-3 d-none d-md-block">
                      <div className="goBack">
                        <Link
                          to="/dashboard"
                          className="back-btn-red"
                          title="Go to Dashboard"
                        >
                          <span className="icons">
                            <img src={backArrowIcon} alt="back icon" />
                          </span>
                          Go to Dashboard
                        </Link>
                      </div>
                    </div>
                  )}
                {!isFullScreen && (
                  <div className="col-12 mb-4 d-flex justify-content-between align-items-center">
                    <h1>{kitchenTitle ? kitchenTitle : "All Orders"}</h1>
                    {/* begin:: filters for web screens */}
                    <div className="d-none d-xl-flex align-items-center">
                      <button
                        type="button"
                        className={`orders__refresh__icon mr-3 ${
                          isRefresh ? "refresh__active" : ""
                        }`}
                        onClick={getListing}
                      >
                        <span>
                          <img
                            src={refreshIcon}
                            alt="fullScreen icon"
                            className="img-fluid"
                          />
                        </span>
                      </button>
                      <button
                        type="button"
                        className="orders__fullscreen__icon"
                        onClick={onOrderFullScreenToggle}
                      >
                        <span>
                          <img
                            src={fullScreenIcon}
                            alt="fullScreen icon"
                            className="img-fluid"
                          />
                        </span>
                      </button>
                      <div className="filters__wrapper__mobile ml-3">
                        <div className="filters__toggle__mobile">
                          <button
                            type="button"
                            className={
                              "filters orders__filter__icon " +
                              (searchTerm ||
                              orderStatus ||
                              marketPlaceStatus ||
                              dateTo ||
                              dateFrom
                                ? "filter__applied"
                                : "")
                            }
                            onClick={onOrderFilterToggle}
                          >
                            <span>
                              <img
                                src={filterIcon}
                                alt="export icon"
                                className="img-fluid"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                      {(userRole === "Admin" ||
                        userRole === "Restaurant Owner") && (
                        <div className="ml-3">
                          <ExportCsv
                            searchFilter={searchTerm}
                            kitchenFilter={selectedKitchenId}
                            statusFilter={orderStatus}
                            marketplaceFilter={marketPlaceStatus}
                            dateFromFilter={dateFrom}
                            dateToFilter={dateTo}
                            pageSize={exportsPageSize}
                            totalRecords={totalRecords}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* end:: filters for web screens */}
                {/* begin:: filters for mobile screens */}
                <div className="order__filters__mobile__screens">
                  <div className="col-12 d-xl-none">
                    <div className="d-flex flex-column w-100 form">
                      <div className="filters__wrapper__mobile mb-3">
                        <div className="d-flex filters__toggle__mobile">
                          <button
                            type="button"
                            className={`orders__refresh__icon filters mr-3 ${
                              isRefresh ? "refresh__active" : ""
                            }`}
                            onClick={getListing}
                          >
                            <span>
                              <img
                                src={refreshIcon}
                                alt="fullScreen icon"
                                className="img-fluid"
                              />
                            </span>
                          </button>
                          <button
                            type="button"
                            className={
                              "filters " +
                              (searchTerm ||
                              orderStatus ||
                              marketPlaceStatus ||
                              dateTo ||
                              dateFrom
                                ? "filter__applied"
                                : "")
                            }
                            onClick={onOrderFilterToggle}
                          >
                            <span>
                              <img
                                src={filterIcon}
                                alt="export icon"
                                className="img-fluid"
                              />
                            </span>
                          </button>
                        </div>
                        {(userRole === "Admin" ||
                          userRole === "Restaurant Owner") && (
                          <ExportCsv
                            searchFilter={searchTerm}
                            kitchenFilter={selectedKitchenId}
                            statusFilter={orderStatus}
                            marketplaceFilter={marketPlaceStatus}
                            dateFromFilter={dateFrom}
                            dateToFilter={dateTo}
                            pageNum={pageNum}
                            pageSize={exportsPageSize}
                            totalRecords={totalRecords}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end:: filters for mobile screens */}
              </div>
            </div>

            {(allOrders?.length === 0 || allOrders === null) && !isLoading ? (
              <div className="managermodifier__noListing">
                <div className="managermodifier__noListing__innerwrapper">
                  <div className="content">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={emptyIocn}
                        alt="no list icon"
                        width="185"
                      />
                    </div>
                    <div className="content-desc">
                      <h3>No Orders Found</h3>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="managermodifier__listinPage orderListing__listinPage">
                <div className="managermodifier__listinPage__wrapper">
                  <table className="table modifierListing__table ordersListing-table">
                    <thead>
                      <tr role="row" className="mat-header-row">
                        <th scope="col" className="mat-header-cell column-1">
                          Customer
                        </th>
                        <th scope="col" className="mat-header-cell column-2">
                          Order
                        </th>
                        <th scope="col" className="mat-header-cell column-3">
                          Date
                        </th>
                        <th
                          scope="col"
                          className="mat-header-cell column-4 pl-2"
                        >
                          Status
                        </th>
                        <th scope="col" className="mat-header-cell column-5">
                          Driver
                        </th>
                        <th scope="col" className="mat-header-cell column-6">
                          Total
                        </th>
                        {!isMobile && (
                          <th scope="col" className="mat-header-cell column-7">
                            Action
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td>
                            <LoaderBlock />
                          </td>
                        </tr>
                      ) : (
                        allOrders?.length &&
                        allOrders?.map((order, idx) => (
                          <ListingRow
                            order={order}
                            key={idx}
                            updatedStatus={
                              latestOrderStatus?.orderId === order.id
                                ? latestOrderStatus
                                : null
                            }
                          />
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-12">
                      <Paginator
                        totalCount={totalRecords}
                        currentPage={pageNum}
                        pageSize={pageSize}
                        pageClick={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <ToastContainer
          enableMultiContainer={true}
          containerId="local"
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnHover
          pauseOnFocusLoss
          draggable={false}
          //limit={notifications?.length >= 3 ? 3 : notifications?.length}
          limit={max_toast}
          progress={undefined}
          closeButton={CustomCloseButton}
        />
      </div>
      {isOpen && (
        <OrderFilterModal
          show={isOpen}
          className="right order__filters__modal"
          onHide={closeModal}
          filtersData={useFilters}
          type={"orders"}
          title={"Filter Orders"}
        />
      )}
    </>
  );
};

export default OrderListing;
