import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import editIcon from "assets/images/edit.svg";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import { UpdateBHBCommissions } from "services/api/brand";
import { SelectDropdownSearch } from "UI/Select-Dropdown/SelectDropdownSearch";
import ConfirmModal from "UI/Modal/ConfirmModal";

const CommissionRow = (props) => {
    const kitchenVBId = props?.associatedBrandId;
    const [creds, setCreds] = useState(props?.credentials);
    const sendInfo = props?.onUpdate;

  const [modalShow, setModalShow] = useState(false);
  const affiliateUserList = props?.affiliateUserList;
  const brandOwnerList = props?.brandOwnerList;
  const [selectedAffiliateUserId, setselectedAffiliateUserId] = useState(creds?.affiliateUserId);
  const [selectedBrandOwnerId, setselectedBrandOwnerId] = useState(creds?.brandOwnerId);
  const [selectedAffiliateUser, setselectedAffiliateUser] = useState(null);
  const [selectedBrandOwner, setselectedBrandOwner] = useState(null);

  const [toggleAffiliateRates, setToggleAffiliateRates] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
   useEffect(() => {
     getAffiliatePayout();
     getBrandOwnerPayout();
   }, []);

    useEffect(() => {
        reset({
          deliveryCommission: creds?.deliveryCommission,
          pickupCommission: creds?.pickupCommission,
          affiliateUserId: creds?.affiliateUserId,
          affiliatePayout: creds?.affiliatePayout,
          brandOwnerId: creds?.brandOwnerId,
          brandOwnerPayout: creds?.brandOwnerPayout,
          affiliatePickupRate: creds?.affiliatePickupRate,
          affiliateDeliveryRate: creds?.affiliateDeliveryRate,
          restaurantPayout: creds?.restaurantPayout,
        });
      setValue("affiliateUserId", creds?.affiliateUserId);
      setValue("brandOwnerId", creds?.brandOwnerId);
      setselectedBrandOwnerId(creds?.brandOwnerId);
      setselectedAffiliateUserId(creds?.affiliateUserId);
      getAffiliatePayout();
      getBrandOwnerPayout();
      setToggleAffiliateRates(creds?.affiliateDeliveryRate || creds?.affiliatePickupRate);
    }, [creds]);

  const [isLoading, setisLoading] = useState(false);

    const validationSchema = Yup.object().shape({
      deliveryCommission: Yup.number()
        .typeError("Only digits allowed")
        .positive()
        .min(0)
        .max(100)
        .required("Please enter Delivery Commission"),
      pickupCommission: Yup.number()
        .typeError("Only digits allowed")
        .positive()
        .min(0)
        .max(100)
        .required("Please enter Pickup Commission"),
      affiliateUserId: Yup.string().nullable(),
      //.required("Please select Affiliate User"),
      //affiliatePayout: Yup.number()
      //.typeError("Only digits allowed")
      //.min(0)
      //.max(100),
      //.required("Please enter Affiliate Payout"),
      //brandOwnerId: Yup.string().nullable(),
      //.required("Please select Brand Owner"),
      //brandOwnerPayout: Yup.number()
      //.typeError("Only digits allowed")
      //.min(0)
      //.max(100),
      //.required("Please enter BrandOwner Payout"),
      affiliatePickupRate: Yup.number()
        .nullable()
        .typeError("Only digits allowed")
        .min(0)
        .max(100),
      affiliateDeliveryRate: Yup.number()
        .nullable()
        .typeError("Only digits allowed")
        .min(0)
        .max(100),
      //restaurantPayout: Yup.number()
      //.typeError("Only digits allowed")
      //.positive()
      //.max(100)
      //.required("Please enter Restaurant Payout"),
    });

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
      clearErrors,
    } = useForm({
      mode: "all",
      resolver: yupResolver(validationSchema),
    });

    const openModal = () => {
        reset({
          deliveryCommission: creds?.deliveryCommission,
          pickupCommission: creds?.pickupCommission,
          affiliateUserId: creds?.affiliateUserId,
          affiliatePayout: creds?.affiliatePayout,
          brandOwnerId: creds?.brandOwnerId,
          brandOwnerPayout: creds?.brandOwnerPayout,
          affiliatePickupRate: creds?.affiliatePickupRate,
          affiliateDeliveryRate: creds?.affiliateDeliveryRate,
          restaurantPayout: creds?.restaurantPayout,
        });
       setValue("affiliateUserId", creds?.affiliateUserId);
       setValue("brandOwnerId", creds?.brandOwnerId);
        setModalShow(true);
        setToggleAffiliateRates(creds?.affiliateDeliveryRate || creds?.affiliatePickupRate);
    };

    const closeModal = () => {
      setModalShow(false);
      reset();
      setselectedAffiliateUserId(creds?.affiliateUserId);
      setselectedBrandOwnerId(creds?.brandOwnerId);
    };
    const [submitFormData, setSubmitFormData] = useState({});
    const closeConfirmPopup = () => {
        setConfirmPopup(false);
        setSubmitFormData({});
    };
  const submitForm = (data) => {
        setConfirmPopup(true);
        setSubmitFormData(data);
    };

    const onSubmit = async () => {
     let data = submitFormData;
     let model = {
        kitchenVBId,
        deliveryCommission: data?.deliveryCommission,
        pickupCommission: data?.pickupCommission,
        affiliateUserId: data?.affiliateUserId,
        affiliatePayout: data?.affiliatePayout,
        brandOwnerId: data?.brandOwnerId,
        brandOwnerPayout: data?.brandOwnerPayout,
        affiliatePickupRate: data?.affiliatePickupRate,
        affiliateDeliveryRate: data?.affiliateDeliveryRate,
        restaurantPayout: data?.restaurantPayout,
        selectedAffiliateUser: selectedAffiliateUser,
        selectedBrandOwner: selectedBrandOwner,
      };
        //if (data?.deliveryCommission || data?.pickupCommission) {
      setisLoading(true);
            await UpdateBHBCommissions(model).then(
                (res) => {
                    toastify("success", res.message);
                    setCreds({
                      deliveryCommission: data?.deliveryCommission,
                      pickupCommission: data?.pickupCommission,
                      affiliateUserId: data?.affiliateUserId,
                      affiliatePayout: data?.affiliatePayout,
                      brandOwnerId: data?.brandOwnerId,
                      brandOwnerPayout: data?.brandOwnerPayout,
                      affiliatePickupRate: data?.affiliatePickupRate,
                      affiliateDeliveryRate: data?.affiliateDeliveryRate,
                      restaurantPayout: data?.restaurantPayout,
                    });
                    sendInfo({
                      deliveryCommission: data?.deliveryCommission,
                      pickupCommission: data?.pickupCommission,
                      affiliateUserId: data?.affiliateUserId,
                      affiliatePayout: data?.affiliatePayout,
                      brandOwnerId: data?.brandOwnerId,
                      brandOwnerPayout: data?.brandOwnerPayout,
                      affiliatePickupRate: data?.affiliatePickupRate,
                      affiliateDeliveryRate: data?.affiliateDeliveryRate,
                      restaurantPayout: data?.restaurantPayout,
                    });
                    setisLoading(false);
                    closeConfirmPopup();
                    setModalShow(false);
                },
                (_) => {
                    setisLoading(false);
                    closeConfirmPopup();
                    setModalShow(false);
                }
            );
      //}
    };
  
   const onAffiliateUserSelect = (data) => {
     if (data) {
       setValue("affiliateUserId", data?.value);
       setselectedAffiliateUserId(data.value);
     }
   };

   const onBrandOwnerSelect = (data) => {
     if (data) {
       setValue("brandOwnerId", data?.value);
       setselectedBrandOwnerId(data.value);
     }
   };

  const getAffiliatePayout = () => {
    if (creds?.affiliateUserId && affiliateUserList?.length) {
      const selectedAffiliateUser = affiliateUserList.find(
        (res) => res.value !== null && res.value.toLowerCase() === creds.affiliateUserId.toLowerCase()
      );
      setselectedAffiliateUser(selectedAffiliateUser?.label);
    }
    else{
      setselectedAffiliateUser('');
    }
  };

  const getBrandOwnerPayout = () => {
    if (creds?.brandOwnerId && brandOwnerList?.length) {
      const selectedBrandOwner = brandOwnerList.find(
        (res) => res.value !== null && res.value.toLowerCase() === creds.brandOwnerId.toLowerCase()
      );
      setselectedBrandOwner(selectedBrandOwner?.label);
    }
    else{
      setselectedBrandOwner('');
    }
  };

  const _handleToggleAffiliateRates = (e) => {
    const isCheck = e.target.checked;
    setToggleAffiliateRates(isCheck);
    setValue('affiliatePickupRate', !isCheck ? 0 : null);
    setValue('affiliateDeliveryRate', !isCheck ? 0 : null);
    setValue('affiliatePayout', isCheck ? 0 : null);
    clearErrors(['affiliatePickupRate', 'affiliateDeliveryRate', 'affiliatePayout']);
  };

    return (
      <>
        <div className="accordion__data__card">
          <div className="row no-gutters justify-content-between">
            <div className="col-10 col-md-10">
              <div className="row">
                <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Delivery Rate</label>
                    <p>{`${creds?.deliveryCommission}%` || "-"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Pickup Rate</label>
                    <p>{`${creds?.pickupCommission}%` || "-"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Affiliate User</label>
                    <p>
                      {selectedAffiliateUser ? `${selectedAffiliateUser}` : "-"}
                    </p>
                  </div>
                </div>
                {/* <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Restaurant Payout %</label>
                    <p>{`${creds?.restaurantPayout}%` || "-"}</p>
                  </div>
                </div> */}
              </div>
              <div className="row">
                {/* <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Affiliate Payout %</label>
                    <p>
                      {selectedAffiliateUser
                        ? `${selectedAffiliateUser} : ${creds?.affiliatePayout}%`
                        : "-"}
                    </p>
                  </div>
                </div> */}
                <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Affiliate Delivery Rate%</label>
                    <p>
                      {creds?.affiliateDeliveryRate
                        ? `${creds.affiliateDeliveryRate}%`
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Affiliate Pickup Rate%</label>
                    <p>
                      {creds?.affiliatePickupRate
                        ? `${creds.affiliatePickupRate}%`
                        : "-"}
                    </p>
                  </div>
                </div>
                {/* <div className="col-12 col-md-4 pb-3 mb-md-4">
                  <div className="data">
                    <label>Brand Owner Payout %</label>
                    <p>
                      {selectedBrandOwner
                        ? `${selectedBrandOwner} : ${creds?.brandOwnerPayout}%`
                        : "-"}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-2 col-md-1">
              <div className="edit__button__wrapper">
                <button
                  title={creds?.apiKey ? "Edit" : "Add"}
                  className="btn edit-btn mr-4"
                  onClick={openModal}
                >
                  <span>
                    <img src={editIcon} alt="edit-icon" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {modalShow && (
          <Modal
            show={modalShow}
            onHide={closeModal}
            centered
            className="credentials__modal"
            id="commission__modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {creds?.deliveryCommission || creds?.pickupCommission
                  ? "Edit"
                  : "Add"}{" "}
                Commission
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="form-row no-gutters">
                  <div className="col-md-12 mb-2 pb-2">
                    <label htmlFor="deliveryCommission">
                      Delivery BHB Commission{" "}
                      <span className="mendatory">*</span>
                    </label>
                    <input
                      id="deliveryCommission"
                      placeholder="e.g. 30"
                      className={`form-control custom-input ${
                        errors?.deliveryCommission ? "is-invalid" : ""
                      }`}
                      {...register("deliveryCommission")}
                    />
                    {errors.deliveryCommission?.message && (
                      <div className="invalid-feedback d-block">
                        {errors.deliveryCommission?.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 mb-2 pb-2">
                    <label htmlFor="pickupCommission">
                      Pickup BHB Commission <span className="mendatory">*</span>
                    </label>
                    <input
                      id="pickupCommission"
                      placeholder="e.g. 15"
                      className={`form-control custom-input ${
                        errors?.pickupCommission ? "is-invalid" : ""
                      }`}
                      {...register("pickupCommission")}
                    />
                    {errors.pickupCommission?.message && (
                      <div className="invalid-feedback d-block">
                        {errors.pickupCommission?.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 mb-2 pb-2">
                        <label htmlFor="affiliatePayout">Affiliate Name</label>
                        <SelectDropdownSearch
                          options={affiliateUserList}
                          placeholder="Select User"
                          selectedValue={onAffiliateUserSelect}
                          value={selectedAffiliateUserId}
                          searchPlaceholder="Select User"
                          {...register("affiliateUserId")}
                          className={`form-control ${
                            errors.affiliateUserId || !selectedAffiliateUserId
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {errors.affiliateUserId?.message &&
                          !selectedAffiliateUserId && (
                            <div className="invalid-feedback d-block">
                              {errors.affiliateUserId?.message}
                            </div>
                          )}
                      </div>
                      {/* <div className="col-md-6 mb-2 pb-2">
                        <label htmlFor="affiliatePayout">Affiliate Payout %</label>
                        <input
                          id="affiliatePayout"
                          placeholder="e.g. 15"
                          className={`form-control ${
                            errors?.affiliatePayout ? "is-invalid" : ""
                          }`}
                          {...register("affiliatePayout")}
                          disabled={toggleAffiliateRates}
                        />
                        <span className="percentage-adornment pr-3">%</span>
                        {errors.affiliatePayout?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.affiliatePayout?.message}
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>

                  {/* <div className="col-md-12 mb-4 mt-2">
                    <div className="custom-checkbox-wrapper d-flex">
                      <label
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          paddingTop: 8,
                          paddingLeft: 24,
                        }}
                        className="custom-checkbox-container"
                      >
                        Add Pickup & Delivery Rates
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            _handleToggleAffiliateRates(e);
                          }}
                          defaultChecked={toggleAffiliateRates}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div> */}

                  {/* {toggleAffiliateRates ? ( */}
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 mb-2 pb-2">
                        <label htmlFor="affiliatePickupRate">
                          Affiliate Pickup Rate%
                        </label>
                        <input
                          id="affiliatePickupRate"
                          placeholder="e.g. 15"
                          className={`form-control ${
                            errors?.affiliatePickupRate ? "is-invalid" : ""
                          }`}
                          {...register("affiliatePickupRate")}
                        />
                        <span className="percentage-adornment pr-3">%</span>
                        {errors.affiliatePickupRate?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.affiliatePickupRate?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-2 pb-2">
                        <label htmlFor="affiliateDeliveryRate">
                          Affiliate Delivery Rate%
                        </label>
                        <input
                          id="affiliateDeliveryRate"
                          placeholder="e.g. 15"
                          className={`form-control ${
                            errors?.affiliateDeliveryRate ? "is-invalid" : ""
                          }`}
                          {...register("affiliateDeliveryRate")}
                        />
                        <span className="percentage-adornment pr-3">%</span>
                        {errors.affiliateDeliveryRate?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.affiliateDeliveryRate?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* ) : null} */}

                  {/* <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 mb-2 pb-2">
                        <label htmlFor="brandOwnerPayout">Brand Owner Name</label>
                        <SelectDropdownSearch
                          options={brandOwnerList}
                          placeholder="Select User"
                          selectedValue={onBrandOwnerSelect}
                          value={selectedBrandOwnerId}
                          searchPlaceholder="Select User"
                          {...register("brandOwnerId")}
                          className={`form-control ${
                            errors.brandOwnerId || !selectedBrandOwnerId
                              ? "is-invalid"
                              : ""
                          }`}
                          id="brand_owner"
                        />
                        {errors.brandOwnerId?.message &&
                          !selectedBrandOwnerId && (
                            <div className="invalid-feedback d-block">
                              {errors.brandOwnerId?.message}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6 mb-2 pb-2">
                        <label htmlFor="brandOwnerPayout">Brand Owner Payout %</label>
                        <input
                          id="brandOwnerPayout"
                          placeholder="e.g. 15"
                          className={`form-control ${
                            errors?.brandOwnerPayout ? "is-invalid" : ""
                          }`}
                          {...register("brandOwnerPayout")}
                        />
                        <span className="percentage-adornment pr-3">%</span>
                        {errors.brandOwnerPayout?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.brandOwnerPayout?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-2 pb-2">
                    <label htmlFor="restaurantPayout">
                      Restaurant Payout <span className="mendatory">*</span>
                    </label>
                    <input
                      id="restaurantPayout"
                      placeholder="e.g. 15"
                      className={`form-control custom-input ${
                        errors?.restaurantPayout ? "is-invalid" : ""
                      }`}
                      {...register("restaurantPayout")}
                    />
                    <span className="percentage-adornment">%</span>
                    {errors.restaurantPayout?.message && (
                      <div className="invalid-feedback d-block">
                        {errors.restaurantPayout?.message}
                      </div>
                    )}
                  </div> */}

                  <div className="col-md-12 action__wrapper d-flex flex-column-reverse d-md-flex justify-content-between">
                    <button
                      type="button"
                      className="cancel-btn red-btn-ghost btn mr-5"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="prelogin-links-btn mb-3"
                      //disabled={isLoading}
                    >
                      {isLoading ? <LoaderInline /> : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        )}
        {confirmPopup && (
          <ConfirmModal
            show={confirmPopup}
            title="Are you sure you want to make changes?"
            subtitle="These changes will apply to all the future orders."
            buttonText="Save & Confirm"
            onCancel={closeConfirmPopup}
            onSubmit={onSubmit}
            onLoading={isLoading}
          />
        )}
      </>
    );
};

export default CommissionRow;
