export const OrderStatus = {
  NEW: 1,
  PREPARING: 2,
  READY: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  REJECTED: 6,
  ALL: 7,
  ACKNOWLEDGED: 8
};

export const DriverStatus = {
  DRIVERREQUESTED: 11,
  DRIVERASSIGNED: 12,
  DRIVERENROUTE: 13,
  DRIVERARRIVED: 14,
  PICKUPCOMPLETE: 15,
  PICKUPENROUTE: 16,
  DROPOFFCOMPLETE: 17,
  DRIVERCANCELLED: 18,
  RETURNENROUTE: 19,
  ORDERRETURNED: 20,
  SCHEDULED: 21,
  DELIVERYFAILED: 22,
  DELIVERYJOBCREATED: 10,
  DELIVERYJOBCREATIONFAILED: 91,
  NONBHBSTATUS: 99,
  COURIERLOCATIONUPDATED:100
}

export const stepIndicatorTitles = [
  { orderStatusId: 1, status: "Order Created" },
  { orderStatusId: 8, status: "Accepted" },
  { orderStatusId: 2, status: "Preparing" },
  { orderStatusId: 3, status: "Ready" },
  { orderStatusId: 4, status: "Completed" },
  { orderStatusId: 5, status: "Cancelled" },
  { orderStatusId: 11, status: "Driver Requested" },
  { orderStatusId: 12, status: "Driver Assigned" },
  { orderStatusId: 13, status: "Driver EnRoute" },
  { orderStatusId: 14, status: "Driver Arrived" },
  { orderStatusId: 15, status: "Pickup Complete" },
  { orderStatusId: 16, status: "Pickup EnRoute" },
  { orderStatusId: 17, status: "Dropoff Complete" },
  { orderStatusId: 18, status: "Driver Cancelled" },
  { orderStatusId: 19, status: "Return EnRoute" },
  { orderStatusId: 20, status: "Order Returned" },
  { orderStatusId: 21, status: "Scheduled" },
  { orderStatusId: 22, status: "Delivery Failed" },
  { orderStatusId: 91, status: "Updating Delivery Job" },
  { orderStatusId: 100, status: "Courier Location Updated" },
];

export const OrderDetailsStrings = {
  new: "New",
  pending: "Pending",
  preparing: "Preparing",
  ready: "Ready",
  completed: "Completed",
  cancelled: "Cancelled",
  rejected: "Rejected",
  acknowledged: "Acknowledged",
};

export const orderStatusStrings = {
  setAsNew: 'New',
  setAsPreparing: 'Set as Preparing',
  acceptOrder: 'Accept Order',
  setAsReady: 'Set as Ready',
  completeOrder: 'Complete Order',
  cancelOrder: 'Cancel Order',
  preparing: 'Preparing',
  setAsCompleted: 'Set as Completed',
  cancelled: 'Cancelled',
  sendToPrinter: 'Send to Printer',
}

export const colour ={
  red : "#da0000",
  white: "#ffffff",
  Timer: '#1b2236',
}

export const OrderStatusLabel = [
  {
    value: OrderStatus.NEW,
    label: "New",
    colourClass: "new",
    needToShow: true,
    labelColor: "new__color",
  },
  {
    value: OrderStatus.PREPARING,
    label: "Preparing",
    colourClass: "preparing",
    needToShow: true,
    labelColor: "preparing__color",
  },
  {
    value: OrderStatus.READY,
    label: "Ready",
    colourClass: "ready",
    needToShow: true,
    labelColor: "ready__color",
  },
  {
    value: OrderStatus.COMPLETED,
    label: "Completed",
    colourClass: "completed",
    needToShow: true,
    labelColor: "completed__color",
  },
  {
    value: OrderStatus.CANCELLED,
    label: "Cancelled",
    colourClass: "cancelled",
    needToShow: true,
    labelColor: "cancelled__color",
  },
  {
    value: OrderStatus.REJECTED,
    label: "Rejected",
    colourClass: "cancelled",
    needToShow: false,
    labelColor: "cancelled__color",
  },
  {
    value: OrderStatus.ALL,
    label: "All",
    colourClass: "",
    needToShow: false,
    labelColor: "",
  },
  {
    value: OrderStatus.ACKNOWLEDGED,
    label: "Acknowledged",
    colourClass: "new",
    needToShow: false,
    labelColor: "new__color",
  },
  // Driver Status Labels
  {
    value: DriverStatus.DRIVERREQUESTED,
    label: "Driver Requested",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DRIVERASSIGNED,
    label: "Driver Assigned",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DRIVERENROUTE,
    label: "Driver EnRoute",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DRIVERARRIVED,
    label: "Driver Arrived",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.PICKUPCOMPLETE,
    label: "Pickup Complete",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.PICKUPENROUTE,
    label: "Pickup EnRoute",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DROPOFFCOMPLETE,
    label: "Dropoff Complete",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DRIVERCANCELLED,
    label: "Driver Cancelled",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.RETURNENROUTE,
    label: "Return EnRoute",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.ORDERRETURNED,
    label: "Order Returned",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.SCHEDULED,
    label: "Scheduled",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DELIVERYJOBCREATED,
    label: "Delivery Created",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DELIVERYFAILED,
    label: "Delivery Failed",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: DriverStatus.DELIVERYJOBCREATIONFAILED,
    label: "Updating Delivery",
    colourClass: "delivery",
    needToShow: false,
    labelColor: "delivery__color",
  },
  {
    value: 0,
    label: "Delivery",
    colourClass: "delivery",
    needToShow: true,
    labelColor: "delivery__color",
  },
];

export const orderStatusDropdown = [
  {
    orderStatusId: 1,
    /*   statusDisplayColor: colour.pending, */
    statusName: OrderDetailsStrings.pending,
    items: [
      {
        id: 2,
        statusDisplayText: orderStatusStrings.acceptOrder,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 3,
        statusDisplayText: orderStatusStrings.setAsReady,
        colorCode: "#c0c0c9",
        selectable: OrderDetailsStrings.disabled,
        disabled: true,
      },
      {
        id: 4,
        statusDisplayText: orderStatusStrings.completeOrder,
        colorCode: "#c0c0c9",
        selectable: OrderDetailsStrings.disabled,
        disabled: true,
      } /* ,
        {
          id: 100,
          statusDisplayText: orderStatusStrings.sendToPrinter,
          selectable: OrderDetailsStrings.disabled,
          disabled: true,
        } */,
    ],
  },
  {
    orderStatusId: 2,
    /*   statusDisplayColor: colour.preparing, */
    statusName: OrderDetailsStrings.preparing,
    items: [
      {
        id: 3,
        statusDisplayText: orderStatusStrings.setAsReady,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 4,
        statusDisplayText: orderStatusStrings.setAsCompleted,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 5,
        statusDisplayText: orderStatusStrings.cancelOrder,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      } /* ,
        {
          id: 100,
          statusDisplayText: orderStatusStrings.sendToPrinter,
          selectable: OrderDetailsStrings.selectable,
          disabled: false,
        } */,
    ],
  },
  {
    orderStatusId: 3,
    /*  statusDisplayColor: colour.ready, */
    statusName: OrderDetailsStrings.ready,
    items: [
      {
        id: 2,
        statusDisplayText: orderStatusStrings.setAsPreparing,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 4,
        statusDisplayText: orderStatusStrings.setAsCompleted,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 5,
        statusDisplayText: orderStatusStrings.cancelOrder,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      } /* ,
          {
            id: 100,
            statusDisplayText: orderStatusStrings.sendToPrinter,
            selectable: OrderDetailsStrings.selectable,
            disabled: false,
          } */,
    ],
  },
  {
    orderStatusId: 4,
    /*    statusDisplayColor: colour.completed, */
    statusName: OrderDetailsStrings.completed,
    items: [
      {
        id: 2,
        statusDisplayText: orderStatusStrings.setAsPreparing,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 3,
        statusDisplayText: orderStatusStrings.setAsReady,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 5,
        statusDisplayText: orderStatusStrings.cancelOrder,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      } /* ,
            {
              id: 100,
              statusDisplayText: orderStatusStrings.sendToPrinter,
              selectable: OrderDetailsStrings.selectable,
              disabled: false,
            } */,
    ],
  },
  {
    orderStatusId: 5,
    /*   statusDisplayColor: colour.cancelled, */
    statusName: OrderDetailsStrings.cancelled,
    items: [
      {
        id: 2,
        statusDisplayText: orderStatusStrings.setAsPreparing,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 3,
        statusDisplayText: orderStatusStrings.setAsReady,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 4,
        statusDisplayText: orderStatusStrings.setAsCompleted,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      } /* ,
        {
          id: 100,
          statusDisplayText: orderStatusStrings.sendToPrinter,
          selectable: OrderDetailsStrings.selectable,
          disabled: false,
        } */,
    ],
  },
  {
    orderStatusId: 6,
    /*  statusDisplayColor: colour.rejected, */
    statusName: OrderDetailsStrings.rejected,
    disabled: false,
  },
  {
    orderStatusId: 8,
    statusName: OrderDetailsStrings.acknowledged,
    items: [
      {
        id: 2,
        statusDisplayText: orderStatusStrings.acceptOrder,
        selectable: OrderDetailsStrings.selectable,
        disabled: false,
      },
      {
        id: 3,
        statusDisplayText: orderStatusStrings.setAsReady,
        colorCode: "#c0c0c9",
        selectable: OrderDetailsStrings.disabled,
        disabled: true,
      },
      {
        id: 4,
        statusDisplayText: orderStatusStrings.completeOrder,
        colorCode: "#c0c0c9",
        selectable: OrderDetailsStrings.disabled,
        disabled: true,
      } 
    ],
  }
];

export const screenNames = {
  orderDetails: "Order Details",
  driver: "Driver"
};
